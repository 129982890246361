import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Deutz: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Deutz,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Deutz"
          d="M616,624l18,26l-24,38l-10,8v22l-18.8,15.6c0,0,0,0,0,0c-3.6-6.7-6.5-13.9-8.4-21.3 c-5.9-22.6-13.2-55.9-13.9-74.6c-0.3-9.3-0.4-18.6,1.3-27.7l0.1-0.3c1.2-6.4,3.2-12.6,6.1-18.4l0,0c1.1-2.1,2.4-4.1,4.1-5.9 L590,564l8,22l-14,10l10,8l16-18l18,6L616,624z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="translate(576.256 653.28)"
          textRendering="geometricPrecision"
        >
          Deutz
        </text>
      </g>
    </>
  );
});
